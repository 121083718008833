import {makeAutoObservable} from "mobx";
import UserApi from "../api/UserApi";
import {
    getAccessTokenFromLocalStorage,
    removeAccessTokenFromLocalStorage,
    setAccessTokenToLocalStorage
} from "../utils/tokenStorage";

class UserStore {
    isLogging = false;
    token = null;

    me = null;
    isLoadingMe = false;

    isLoadingRequest = false;
    requests = [];

    isLoadingMyRequest = false;
    myRequests = [];

    isSendingAcceptRequest = false;

    isSendingDeclineRequest = false;

    // ToDo: for decline

    isLoggedIn = false;
    isInit = false;

    constructor() {
        this.token = getAccessTokenFromLocalStorage();
        setTimeout(() => {
            if (this.token) {
                this.setLoggedIn(true);

                this.onInitUser();
            }

            this.setIsInit(true);
        });

        makeAutoObservable(this);
    }

    onInitUser() {
        this.updateMe();
        this.updateRequests();
        this.updateMyRequests();
    }

    async logout() {
        // TODO: logout доделать
        this.isLoggedIn = false;
        removeAccessTokenFromLocalStorage();
    }

    async login(loginData) {
        this.setIsLogging(true);
        try {
            const data = await UserApi.login(loginData);
            this.setAccessToken(data.data.token);
            this.setLoggedIn(true);

            this.onInitUser();

            return true
        } catch (e) {} finally {
            this.setIsLogging(false);
        }
    }

    async acceptRequest(requestId) {
        this.setIsSendingAcceptRequest(true);
        try {
            await UserApi.acceptRequest(requestId);
        } catch (e) {} finally {
            this.setIsSendingAcceptRequest(false);
        }
    }

    async declineRequest(requestId) {
        this.setIsSendingDeclineRequest(true);
        try {
            await UserApi.declineRequest(requestId);
        } catch (e) {} finally {
            this.setIsSendingDeclineRequest(false);
        }
    }

    async updateAllRequests() {
        await Promise.all([
            this.updateRequests(),
            this.updateMyRequests()
        ]);
    }

    async updateRequests() {
        this.setIsLoadingRequest(true);
        try {
            await this.loadRequests();
        } catch (e) {}
        this.setIsLoadingRequest(false);
    }

    async loadRequests() {
        const response = await UserApi.getRequests();
        this.setRequests(response.data);
    }

    async updateMyRequests() {
        this.setIsLoadingMyRequest(true);
        try {
            await this.loadMyRequests();
        } catch (e) {}
        this.setIsLoadingMyRequest(false);
    }

    async loadMyRequests() {
        const response = await UserApi.getMyRequests();
        this.setMyRequests(response.data);
    }

    async updateMe() {
        this.setIsLoadingMe(true);
        try {
            await this.loadMe();
        } finally {
            this.setIsLoadingMe(false);
        }
    }

    async loadMe() {
        const response = await UserApi.me();
        this.setMe(response.data);
    }

    handleWebSocketEvent(event) {
        const { type, text, user, request_id } = event;

        switch (type) {
            case 'new_request':
                this.addRequest({
                    id: request_id,
                    text: text,
                    type: "Новая заявка",
                    user: {
                        "t_first_name": user.t_first_name,
                        "t_last_name": user.t_last_name,
                        "t_username": user.t_username
                    }
                });
                break;
            default:
                console.warn(`Unhandled event type: ${type}`);
        }
    }

    addRequest(newRequest) {
        this.requests = [newRequest, ...this.requests];
    }

    setRequests(requests) {
        this.requests = requests;
    }

    setIsLogging(isLogging) {
        this.isLogging = isLogging;
    }

    setAccessToken(token) {
        this.token = token;
        setAccessTokenToLocalStorage(token);
    }

    setLoggedIn(isLoggedIn) {
        this.isLoggedIn = isLoggedIn;
    }

    setIsInit(isInit) {
        this.isInit = isInit;
    }

    setRequests(requests) {
        this.requests = requests;
    }

    setIsLoadingRequest(isLoadingRequest) {
        this.isLoadingRequest = isLoadingRequest;
    }

    setIsLoadingMyRequest(isLoadingMyRequest) {
        this.isLoadingMyRequest = isLoadingMyRequest;
    }

    setMyRequests(myRequests) {
        this.myRequests = myRequests;
    }

    setIsSendingAcceptRequest(isSendingAcceptRequest) {
        this.isSendingAcceptRequest = isSendingAcceptRequest;
    }

    setMe(me) {
        this.me = me;
    }

    setIsLoadingMe(isLoadingMe) {
        this.isLoadingMe = isLoadingMe;
    }

    setIsSendingDeclineRequest(isSendingDeclineRequest) {
        this.isSendingDeclineRequest = isSendingDeclineRequest;
    }
}

const userStore = new UserStore();
export default userStore;
