import { observer } from "mobx-react";
import userStore from "../store/UserStore";
import { useState } from "react";
import { Pagination, Stack, Typography, Button } from "@mui/material";
import { RequestCard } from "../components/request/RequestCard";
import { RequestViewActions } from "../components/request/RequestViewActions";
import { RequestStatusEnum } from "../api/UserApi";
import { WrapperForRequests } from "../components/request/WrapperForRequests";
import axios from "axios";
import { BASE_URL } from "../constants";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Autocomplete } from "@mui/material";

export const ArchiveMyRequests = observer(() => {
    const [value, setValue] = useState("");
    const [data_type, setData_type] = useState("Выберите тип заявки")
    const [ModalPanel, setModalPanel] = useState(false)
    const requestTypes = [
        { label: "Организационный вопрос", value: "org_question" },
        { label: "Технический вопрос", value: "tech_question" }
    ];

    const handleDownload = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/user/request/statistics`, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Статистика заявок за месяц.xls`);
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Ошибка при скачивании файла', error);
        }
    };

    return (
        <div className="zak">
        <WrapperForRequests title="Архивные заявки" onClickUpdate={() => {
            userStore.updateMyRequests();
        }}>
            <div className="cont">
                <Button variant="contained" color="primary" onClick={handleDownload}>Выгрузка за месяц</Button>
            </div>
            <div className="flex">
                <TextField
                    sx={{ width: "70%" }}
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                    label="Введите имя или фамилию пользователя"
                />
            </div>
            
            <Internal filter={value} />
            

        </WrapperForRequests>
        </div>
    );
});

const Internal = observer(({ filter }) => {
    const reqs = userStore.myRequests.filter(req => req.status === RequestStatusEnum.DONE || req.status === RequestStatusEnum.DECLINED);

    const filteredReqs = reqs.filter(req => {
        if (!req.user || !req.user.t_first_name || !req.user.t_last_name) return false;
        const fullName = `${req.user.t_first_name} ${req.user.t_last_name}`.toLowerCase();
        const reverseFullName = `${req.user.t_last_name} ${req.user.t_first_name}`.toLowerCase();
        return fullName.includes(filter.toLowerCase()) || reverseFullName.includes(filter.toLowerCase());
    });

    const [page, setPage] = useState(1);
    const perPage = 5;
    const totalPages = Math.ceil(filteredReqs.length / perPage);

    const paginatedReqs = filteredReqs.slice((page - 1) * perPage, page * perPage);

    return (
        <>
            <Typography>{userStore.isLoadingMyRequest ? 'Загрузка...'
                : !userStore.isLoggedIn ? 'Вы не авторизованы'
                    : filteredReqs.length === 0 ? 'Нет заявок, соответствующих фильтру'
                        : ''}</Typography>
            <Stack spacing={2}>
                {paginatedReqs.map(request => (
                    <RequestCard key={request.id} request={request} detail>
                        <RequestViewActions requestId={request.id} />
                    </RequestCard>
                ))}
            </Stack>
            <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
            />
        </>
    );
});
