import { Button, Container, Paper, Stack, TextField } from "@mui/material";
import { observer } from "mobx-react";
import webSocketStore from "../../store/WebSocketStore";
import { useEffect, useRef, useState } from "react";
import { RequestMessage } from "./RequestMessage";
import { RequestStatusEnum } from "../../api/UserApi";
import './RequestMessage.css';
import input from "./img/inputimage.png"
import axios from "axios";
import { BASE_URL } from "../../constants";
import Cookies from 'js-cookie';

export const RequestChat = observer(() => {
    const stackMessagesRef = useRef();
    const [messageInput, setMessageInput] = useState("");
    const messages = webSocketStore.request.messages;
    const [file, setFile] = useState(null);
    const [messageSent, setMessageSent] = useState(false);
    const [panel, setPanel] = useState("")
    const [isEditing, setIsEditing] = useState(false);
    const [initialText, setInitialText] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    const sendMessage = () => {
        if (!messageInput && !file) return;

        if (file) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const fileData = event.target.result;

                if (file.type.startsWith('image/')) {
                    webSocketStore.binarySocketService.sendPhotoToUser(fileData);
                } else if (file.type.startsWith('video/')) {
                    webSocketStore.binarySocketService.sendVideoToUser(fileData);
                }
            };
            reader.readAsDataURL(file);
            setFile(null);
        } else {
            webSocketStore.binarySocketService.sendMessageToUser(messageInput);
        }

        setMessageInput("");
    };

    const instantScrollDown = () => {
        stackMessagesRef.current?.scrollIntoView({ behavior: 'auto', block: "end", inline: "nearest" });
    };

    useEffect(() => {
        instantScrollDown();
        const messageExists = messages.some(
            (mess) => mess.text === "Мы приняли вашу заявку, ниже вы можете написать сообщения:"
        );

        if (!messageExists && !messageSent) {
            webSocketStore.binarySocketService.sendMessageToUser("Мы приняли вашу заявку, ниже вы можете написать сообщения:");
            setMessageSent(true);
        }
        const csrfToken = Cookies.get('csrftoken');
        const headers = {};
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/user/draft`, {
                    headers: headers
                });
                setPanel(response.data);
                setInitialText(response.data);
            } catch (error) {
                console.error("Error fetching FAQ data:", error);
            }
        };

        fetchData();

        webSocketStore.stackMessagesRef = stackMessagesRef;
    }, [messages, messageSent]);

    const handleSave = async () => {
        try {
            const csrfToken = Cookies.get('csrftoken');
            const headers = {};
            if (csrfToken) {
                headers['X-CSRFToken'] = csrfToken;
            }
            let form_panel = {
                "text": panel
            }
            await axios.patch(`${BASE_URL}/user/draft`, form_panel, {
                headers: headers
            });
            setInitialText(panel);
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving the text:', error);
        }
    };

    const fast_function = (text) => {
        setIsDisabled(true);
        webSocketStore.binarySocketService.sendMessageToUser(text);

        setTimeout(() => {
            setIsDisabled(false);
        }, 3000);
    }

    return (
        <Container>
            <Stack sx={{ padding: 2 }}>
                <Paper elevation={0} sx={{ height: "80vh", overflowY: "scroll" }}>
                    <Stack spacing={2} p={1} justifyContent="flex-end" ref={stackMessagesRef}>
                        {messages.map(message => <RequestMessage message={message} key={message.id} />)}
                    </Stack>
                </Paper>
                {
                    webSocketStore.request.status === RequestStatusEnum.IN_PROGRESS &&
                <div className="fast_carts">
                    <div className="fast_panel"
                            onClick={() => !isDisabled && fast_function("Здравствуйте ! Это техническая поддержка сайта hr.alabuga.ru")}
                            style={{ pointerEvents: isDisabled ? 'none' : 'auto', opacity: isDisabled ? 0.5 : 1 }}>
                            Здравствуйте ! Это техническая поддержка сайта hr.alabuga.ru.
                        </div>
                        <div className="fast_panel"
                            onClick={() => !isDisabled && fast_function("Какой у вас вопрос ?")}
                            style={{ pointerEvents: isDisabled ? 'none' : 'auto', opacity: isDisabled ? 0.5 : 1 }}>
                            Какой у вас вопрос ?
                        </div>
                        <div className="fast_panel"
                            onClick={() => !isDisabled && fast_function("У вас остались еще какие-нибудь вопросы ?")}
                            style={{ pointerEvents: isDisabled ? 'none' : 'auto', opacity: isDisabled ? 0.5 : 1 }}>
                            У вас остались еще какие-нибудь вопросы ?
                        </div>
                        <div className="fast_panel"
                            onClick={() => !isDisabled && fast_function("Рады были помочь ! Всего доброго !")}
                            style={{ pointerEvents: isDisabled ? 'none' : 'auto', opacity: isDisabled ? 0.5 : 1 }}>
                            Рады были помочь ! Всего доброго !
                        </div>
                </div>
                }
                {
                    webSocketStore.request.status === RequestStatusEnum.IN_PROGRESS &&
                    <Stack className="column" direction="row">
                        <div className="input-wrapper">
                            <input className="input" type="file" onChange={(event) => setFile(event.target.files[0])} />
                            <img src={file ? URL.createObjectURL(file) : input} alt="Upload" />
                        </div>
                        <TextField
                        sx={{ width: "100%" }}
                        value={messageInput}
                        onChange={(e) => setMessageInput(e.target.value)}
                        label="Введите сообщение"
                        multiline
                        minRows={1} // Минимальное количество строк
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault(); // Предотвращение новой строки
                                sendMessage();
                            }
                        }}
                    />
                        <Button
                            variant="contained"
                            sx={{ width: "170px" }}
                            onClick={sendMessage}
                        >
                            Отправить
                        </Button>
                    </Stack>
                }
            </Stack>
            {
                webSocketStore.request.status === RequestStatusEnum.IN_PROGRESS &&
                <div className="container_panel">
                <div className="text_panel">
                    {isEditing ? (
                        <TextField
                            multiline
                            rows={11}
                            value={panel}
                            onChange={(e) => setPanel(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    ) : (
                        <div onClick={() => setIsEditing(true)}><TextField
                        multiline
                        rows={11}
                        value={panel}
                        onChange={(e) => setPanel(e.target.value)}
                        variant="outlined"
                        fullWidth
                    /></div>
                    )}
                </div>
                <div className="dis">
                        <Button
                            variant="contained"
                            sx={{ width: "170px" }}
                            onClick={handleSave}
                        >
                            Сохранить
                        </Button>
                </div>
            </div>
            }
        </Container>
    );
});
