import {Container, Paper, Stack, Typography} from "@mui/material";
import {RoleUserEnum, USER_ROLES} from "../../api/UserApi";
import {textFormatTgUser} from "../../utils/tgUser";
import { BASE_MEDIA } from "../../constants";
import './RequestMessage.css'; 
import { formatCustomDateHour } from "../../utils/date";
import {Avatar} from "@mui/material";
function formatUserFrom(message) {
    const user = message.user;

    if (USER_ROLES.includes(user.role)) {
        return <div className="flex"><Avatar /><p className="margin">Сообщение от {user.username}</p></div>
    } else if (user.role === RoleUserEnum.TG_USER) {
        return <div className="flex"><Avatar /><p className="margin">{textFormatTgUser(user)}</p></div>
    } else {
        return "Неизвестный пользователь"
    }
}

export const RequestMessage = ({message}) => {
    return (
        <Paper key={message.id}>
            <Stack direction="row" p={1} spacing={2}>
                <Container sx={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <Stack className="width" p={1} spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5">{formatUserFrom(message)}</Typography>
                            
                        </Stack>
                            {message.image ? (
                                <>
                                    <img className="img" src={BASE_MEDIA + message.image} alt="" />
                                    <Typography>{message.text}</Typography>
                                    <p className="p">{formatCustomDateHour(message.created_at)}</p>
                                </>
                            ) : message.video ? (
                                <>
                                    <video className="video" controls>
                                        <source src={BASE_MEDIA + message.video} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <Typography>{message.text}</Typography>
                                    <p className="p">{formatCustomDateHour(message.created_at)}</p>
                                </>
                            ) : (
                                <>
                                    <Typography>{message.text}</Typography>
                                    <p className="p">{formatCustomDateHour(message.created_at)}</p>
                                </>
                            )}

                        
                    </Stack>
                </Container>
            </Stack>
        </Paper>
    )
}